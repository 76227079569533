<template>
  <div>
    <v-row class="mx-0 my-8 d-flex align-center justify-end">
      <v-col cols="12" md="6" lg="6" class="pl-0">
        <v-text-field v-model="search" prepend-inner-icon="mdi-magnify" label="検索" outlined hide-details
          dense></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="6" class="px-0">
        <chip-with-icon @click="dialog = true" icon="mdi-plus" label="新規お問合わせ" :isOutlined="false" chipColor="primary"
          iconColor="white"></chip-with-icon>
      </v-col>
    </v-row>

    <v-card elevation="0" class="mt-7 mx-0 pa-0">
      <v-data-table :headers="headers" :items="items" item-key="inquiryId" :sort-by="['close', 'created']"
        :sort-desc="[false, true]" class="elevation-0" :search="search" :loading="loading" :page.sync="page"
        :items-per-page="itemsPerPage" hide-default-footer>
        <template v-slot:footer>
          <v-row class="text-center pt-2 align-center mx-2 mt-0" width="90%" wrap style="border-top:1px solid #bbbbbb;">
            <v-col class="text-truncate opacity-07 body-2 mb-0 pa-0" cols="12" md="12">
              {{ pageText }}
            </v-col>
            <v-col cols="12" md="12">
              <v-pagination v-if="pageCount > 1" v-model="page" color="primary" class="mt-0" :length="pageCount">
              </v-pagination>
            </v-col>
          </v-row>
        </template>

        <template v-if="!$vuetify.breakpoint.xs" v-slot:body="{ items: des }">
          <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
          <tbody>
            <tr v-for="d in des" :key="d.inquiryId" @click="showDetail(d)">
              <td>{{ makeTextShort(d.name) }}</td>
              <td v-if="!$vuetify.breakpoint.smAndDown">{{ makeTextShort(d.email) }}</td>
              <td>{{ d.type }}</td>
              <td>{{ makeTextShort(d.details) }}</td>
              <td>{{ d.created }}</td>
              <td>
                <v-chip v-if="d.close" class="ma-2" color="green" text-color="white">解決</v-chip>
                <v-chip v-else class="ma-2" color="red lighten-1" text-color="white">未解決</v-chip>
              </td>
            </tr>
          </tbody>
        </template>

        <!-- スマホ用 -->
        <template v-else v-slot:body="{ items: des }">
          <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
          <tbody>
            <tr v-for="d in des" :key="d.inquiryId" @click="showDetail(d)" class="d-flex flex-wrap pa-2"
              style="border-bottom:0.1px solid #bbbbbb ; ">
              <p class="font-weight-bold text-body-2" style="width:60%;">{{ d.type }}</p>
              <p class="text--secondary text-caption mt-auto text-end" style="width:40%;">{{ d.created }}</p>
              <p class="ma-0 text-body-2" style="width:100%;">
                <span class="text-caption ">名前<span class="white--text">＿</span> :</span> {{ d.name }}
              </p>
              <p class="ma-0 text-body-2" style="width:100%;">
                <span class="text-caption ">メール :</span> {{ d.email }}
              </p>
              <p class="ma-0 text-body-2" style="width:100%;">
                <span class="text-caption ">状態<span class="white--text">＿</span> :</span>
                <v-chip v-if="d.close" class="ma-2" color="green" text-color="white">解決</v-chip>
                <v-chip v-else class="ma-2" color="red lighten-1" text-color="white">未解決</v-chip>
              </p>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialog" max-width="600px" loading="false" :max-height="$vuetify.breakpoint.xs ? '100%' : '400'"
      :fullscreen="$vuetify.breakpoint.xs" style="z-index:2100;">
      <Inquiry v-on:closeEmit="close" v-on:createInquiryEmit="createInquiry" />
    </v-dialog>

    <v-dialog v-model="dialogDetail" max-width="600px" :fullscreen="$vuetify.breakpoint.xs" loading="false"
      style="z-index:2000;">
      <DetailInquiry :detailItem="detailItem" v-on:closeEmit="close" v-on:changeStatusEmit="changeStatus" />
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { decodeTime } from "ulidx";

const Inquiry = () => import("@/components/Inquiry.vue");
const ChipWithIcon = () => import("@/components/ChipWithIcon.vue");
const DetailInquiry = () => import("@/components/DetailInquiry.vue");

export default {
  components: { Inquiry, ChipWithIcon, DetailInquiry },
  name: "TableInquiry",
  data() {
    return {
      loading: false,
      dialog: false,
      dialogDetail: false,
      detailItem: null,
      search: null,
      items: [],
      itemsPerPage: 10,
      page: 1,
      inquiryType: ["アカウントや請求について", "データ容量の追加について", "バグや不具合について", "その他"],
    };
  },
  computed: {
    pageText() {
      return `${Math.max(1, (this.page - 1) * this.itemsPerPage)} - ${Math.min(
        this.page * this.itemsPerPage,
        this.totalRecords
      )} of ${this.totalRecords} `;
    },
    totalRecords() {
      return this.items.length;
    },
    pageCount() {
      return Math.ceil(this.totalRecords / this.itemsPerPage);
    },
    headers() {
      if (this.$vuetify.breakpoint.sm) {
        return [
          { text: "お名前", align: "start", sortable: true, value: "name", width: "10%" },
          { text: "種類", align: "start", sortable: true, value: "type", width: "15%" },
          { text: "お問合せ内容", align: "start", sortable: true, value: "details", width: "15%" },
          { text: "投稿日", align: "start", sortable: true, value: "created", width: "15%" },
          { text: "状態", align: "start", sortable: true, value: "close", width: "10%" },
        ];
      } else {
        return [
          { text: "お名前", align: "start", sortable: true, value: "name", width: "10%" },
          { text: "メールアドレス", align: "start", sortable: true, value: "email", width: "15%" },
          { text: "種類", align: "start", sortable: true, value: "type", width: "15%" },
          { text: "お問合せ内容", align: "start", sortable: true, value: "details", width: "20%" },
          { text: "投稿日", align: "start", sortable: true, value: "created", width: "15%" },
          { text: "状態", align: "start", sortable: true, value: "close", width: "10%" },
        ];
      }
    },
  },

  async created() {
    await this.getData();
    this.detailItem = { name: "-" };
  },

  methods: {
    getData: async function () {
      try {
        this.loading = true;
        const response = await axios.get(
          `${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/inquiry`,
          {
            headers: {
              "X-Api-Key": process.env.VUE_APP_KEY,
              SessionId: this.$store.state.auth.login.sessionId,
              "Content-Type": "application/json",
            },
          }
        );

        this.items = response.data.results;

        for (let i = 0; i < this.items.length; i++) {
          this.items[i].created = this.makeCreateTime(this.items[i].inquiryId);
          this.items[i].type = this.inquiryType[this.items[i].type];
        }

        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },

    close() {
      this.dialog = false;
      this.dialogDetail = false;
    },

    showDetail(item) {
      this.detailItem = item;
      this.dialogDetail = true;
    },

    makeCreateTime(id) {
      let time = new Date(decodeTime(id));
      return `${time.toLocaleDateString("ja-JP")} ${time.toLocaleTimeString("ja-JP")}`;
    },

    makeTextShort(text) {
      if (text) {
        let len = text.length;
        if (len > 15) return text.substr(0, 14) + "...";
        return text;
      }
    },

    changeStatus() {
      let index = this.items.indexOf(this.detailItem);
      this.items[index].close = true;
      this.dialogDetail = false;
    },

    createInquiry(id, item) {
      if (!item.email) item.email = this.$store.state.user_model.userData.userId;
      this.items.push({
        inquiryId: id,
        name: item.name,
        email: item.email,
        type: this.inquiryType[item.type],
        details: item.details,
        created: this.makeCreateTime(id),
        close: false,
      });
    },
  },
};
</script>
